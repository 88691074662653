/* eslint-disable camelcase */
import addRocEventHandler from 'shared/util/events/rocEventUtils';
import gtag from 'features/tracking/gtag';

export default function setup() {
	addRocEventHandler('user-logged-in', () => {
		gtag('event', 'login', {
			method: 'Roc',
		});
	});

	addRocEventHandler('banner-click', ({ bannerName }) => {
		gtag('event', 'click', {
			event_label: bannerName,
			event_category: 'banner',
		});
	});

	addRocEventHandler('banner-impression', ({ bannerName }) => {
		gtag('event', 'impression', {
			event_label: bannerName,
			event_category: 'banner',
		});
	});
}
